<template>
  <b-row class="mt-2 mb-3">
    <b-col>
      <input class="form-control" type="date" v-model="form.start" />
    </b-col>
    <b-col>
      <input class="form-control" type="date" v-model="form.end" />
    </b-col>
    <b-col>
      <a class="btn btn-primary bg-primary font-weight-bolder mr-1 font-size-sm rounded-lg" @click="fetch">Lookup</a>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SearchWidget",
  data() {
    return {
      form: {
        start: new Date().toISOString().slice(0,10),
        end: new Date().toISOString().slice(0,10),
      },
    };
  },
  methods: {
    fetch(){
      this.$emit('searchOrder' , {
        "start" : this.form.start,
        "end" : this.form.end
      })
    },
  },
};
</script>